import axios from 'axios';
import {FullSurvey, SessionResult} from 'models/survey';
import {FormikValues} from 'formik';
import {Response} from '../apiTypes';
import { getFromSessionStorage } from 'utils/common';
import { localStorageKeys } from 'consts/LocalStorageKeys';

const instance = axios.create({
    baseURL: process.env.REACT_APP_QUALTRICS_API_URL,
});

export const Api = {
    getSurvey: (id: string): Promise<Response<FullSurvey>> =>
        instance.get(`/surveys/${id}`, {
            headers: {
                'X-API-TOKEN': getFromSessionStorage(localStorageKeys.content)?.ApiKeys.Qualtrics,
            }
        }).then(({ data }) => data),

    startSession: (id: string): Promise<Response<SessionResult>> =>
        instance.post(`/surveys/${id}/sessions`, {}, {
            headers: {
                'X-API-TOKEN': getFromSessionStorage(localStorageKeys.content)?.ApiKeys.Qualtrics,
            }
        }).then(({ data }) => data),

    updateSession: (id: string, sessionId: string, data: FormikValues): Promise<Response<SessionResult>> =>
        instance.post(`/surveys/${id}/sessions/${sessionId}`, data, {
            headers: {
                'X-API-TOKEN': getFromSessionStorage(localStorageKeys.content)?.ApiKeys.Qualtrics,
            }
        }).then(({ data }) => data),

    // getResponse: (surveyId: string): Promise<Response<any>> =>
    //     instance.post(`/surveys/${surveyId}/responses`, {'values':{}},{
    //         headers: {
    //             'X-API-TOKEN': getFromSessionStorage(localStorageKeys.content)?.ApiKeys.Qualtrics,
    //         },
    //     }).then(({ data }) => data),

    // getSurveyResult: (surveyId: string, responseId: string): Promise<Response<any>> =>
    //     instance.get(`/surveys/${surveyId}/responses/${responseId}`,{
    //         headers: {
    //             'X-API-TOKEN': getFromSessionStorage(localStorageKeys.content)?.ApiKeys.Qualtrics,
    //         },
    //     }).then(({ data }) => data),

    getCurrentSession: (id: string, sessionId: string): Promise<Response<SessionResult>> =>
        instance.get(`/surveys/${id}/sessions/${sessionId}`, {
            headers: {
                'X-API-TOKEN': getFromSessionStorage(localStorageKeys.content)?.ApiKeys.Qualtrics,
            }
        }).then(({ data }) => data),
};
